import { homeCardsItems } from "@/common/fields/home-cards-items";
import { sidebarItems, sidebarItemsFlat } from "@/common/fields/sidebar-items";

export const verifyScope = (scopes = [], allowedScope) => {
    if (!allowedScope) return true;
    return scopes.some(
        (scope) =>
            scope === allowedScope ||
            scope === `${allowedScope}_` ||
            scope === "*"
    );
};

export const generateSidebarAdminByScopes = (scopes = []) => {
    return sidebarItems.reduce((result, item) => {
        const itemsChild = item.items.filter(
            (itemChild) =>
                verifyScope(scopes, itemChild.scope) && !itemChild.hide
        );
        if (itemsChild.length) {
            return [
                ...result,
                {
                    ...item,
                    items: itemsChild
                }
            ];
        }
        return result;
    }, []);
};

export const generateSidebarBasicByScopes = (scopes = []) => {
    return sidebarItemsFlat.filter(
        (item) => verifyScope(scopes, item.scope) && !item.hide
    );
};

export const generateHomeCards = (scopes = []) => {
    const cardLinksAvailable = homeCardsItems.filter((item) =>
        verifyScope(scopes, item.scope)
    );
    let cardLinksActive = [...cardLinksAvailable];
    if (cardLinksAvailable.length >= 13) {
        cardLinksActive = cardLinksAvailable.filter((item) => item.isDefault);
    }
    return { cardLinksAvailable, cardLinksActive };
};
