const mutations = {
    SET_NOTIFICATIONS(state, payload) {
        state.notifications = payload;
    },
    ADD_NOTIFICATIONS(state, payload) {
        const auxItems = [...state.notifications, ...payload];
        const diffItems = auxItems.filter(
            (value, index, self) =>
                self.findIndex((item) => item.id === value.id) === index
        );
        state.notifications = [...diffItems];
    },
    SET_ERROR_NOTIFICATIONS(state, payload) {
        state.error = payload;
    },
    UPDATE_NOTIFICATION(state, payload) {
        const aux = [...state.notifications];
        const index = aux.findIndex((p) => p.id === payload.id);

        if (index > -1) {
            aux[index] = {
                ...aux[index],
                ...payload
            };
            state.notifications = [...aux];
        }
    }
};

export default mutations;
